@use '/styles/variables' as *;

.wrapper {
  display: grid;
  gap: 40px;
}

.title {
  text-transform: uppercase;
  color: map-get($colors, g-white);
  font-size: map-get($font-sizes, lg);
  letter-spacing: 0.12em;
}

.placeholder {
  padding: 0 20px;
  height: 160px;
  width: 90%;
}
