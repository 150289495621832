@use '/styles/variables' as *;
@use '/styles/media' as *;

.item {
  width: 100%;
  display: grid;
  --template: auto auto;
  grid-template-columns: var(--template);
  gap: 28px;
  grid-auto-flow: column;
  align-items: center;
  padding: 24px;

  @include media-breakpoint-down(lg) {
    padding: 16px;
    gap: 10px;
  }

  &.item-mobile {
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }

  animation: init 0.5s ease-in-out;
  will-change: background-color;
}

.link-style {
  text-shadow: none;
  transition: none;
  padding: 0;
  text-align: left;

  &:hover,
  &:active {
    text-shadow: none !important;
  }
}

.cell {
  @include media-breakpoint-up(lg) {
    &:hover,
    &:active {
      background-color: color(elevated);

      &:first-child:not(:only-child) {
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child:not(:only-child) {
        border-radius: 8px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      &:only-child {
        border-radius: 8px;
      }
    }
  }

  &-button {
    display: block;
    text-align: left;
    padding: 0;
    filter: none;
    text-shadow: none;
    transition: none;
    border: 0;

    &:hover,
    &:active {
      .text-fields {
        text-shadow: none !important;
      }
    }

    & .date-label {
      display: grid;
      grid-template-rows: 13px 30px 13px;
      text-align: center;
      padding: 0.5em 0.6em;
      margin-right: 1em;
      min-width: 56px;
      width: fit-content;
      border-radius: 1em;
      background-color: color(elevated);
      border: 1px solid color(dividers);

      span:not(.middle) {
        line-height: 16px;
        font-size: 13px;
      }

      .middle {
        line-height: 28px;
        font-size: 20px;
      }
    }
  }
}

.separator {
  height: 1px;
  width: 100%;
  background-color: color(dividers);
}

.selling-fast-label {
  display: flex;
  align-items: center;
  font-size: toRem(14);

  & .icon {
    margin-right: 0.5em;
    height: 1em;
    width: 1em;
  }
}
