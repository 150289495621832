@import '/styles/variables';

.wrapper {
  position: relative;
  overflow: hidden;
  transition: 0.5s opacity linear;
}

.ar-4-3 {
  padding-top: 75%;
  img {
    @extend .absolute;
  }
}
.ar-16-9 {
  padding-top: 56.25%;
  img {
    @extend .absolute;
  }
}

.rounded {
  border-radius: 50%;
}

.image {
  transition: 0.3s opacity;
  object-position: center;
  will-change: opacity;
  object-fit: cover;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}
