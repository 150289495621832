@use 'sass:meta';
@use 'utils' as u;

$heading-sizes: (
  h1: 3rem,
  h2: 2rem,
  h3: 1.75rem,
  h4: 1.5rem,
  h5: 1.125rem,
  h6: 1rem,
);

$font-sizes: (
  xl: 1.25rem,
  lg: 1.125rem,
  md: 1rem,
  sm: 0.875rem,
  xs: 0.75rem,
  h5: 14px,
  body: 0.9375rem,
);

$line-heights: (
  h5: 20px,
  body: 28px,
);

$font-weights: (
  300: 300,
  400: 400,
  500: 500,
  700: 700,
);

$border-radiuses: (
  lg: 20px,
  md: 12px,
  sm: 4px,
);

$colors: (
  green: #73ff73,
  g-green: #61f262,
  g-green-dark: #1be11d,
  g-green-darker: #14a511,
  teal: #17d9be,
  teal-2: #00E7FF,
  purple: #7021e8,
  neon-purple: #ac47ccfc,
  light-purple: #999cff,
  red: #ff4050,
  pink: #ff3366,
  pink-real: #ff00b8,
  yellow: #ffc61a,
  orange: #f16d2c,
  blue: #4d90ff,
  white: #ffffff,
  gray: #333333,
  black: #000000,
  light-gray: #737373,
  elevated: #0a0a0a,
  dividers: #1f1f1f,
  neutral: #a6a6a6,
  // old colors
  g-black: #040505,
  g-white: #fefefe,
  g-gray-1: #a2a3a3,
  g-gray-2: #6e6f70,
  g-gray-3: #3d3e40,
  g-gray-4: #313233,
  g-gray-5: #171819,
  g-gray-6: #0e0f0f,
  g-orange: #ffb219,
  g-orange-1: #ffc61a0d,
  g-input-hover: #1e2021,
);

@function color($color) {
  @return map-get($colors, $color);
}

$g-font-family: 'Work sans', sans-serif;
$g-heading-font-family: 'Havelock Titling', sans-serif;
$g-letter-spacing: 0.04em;
$g-letter-spacing-uppercase: 0.1em;

$spaces: (
  xxs: 2px,
  xs: 4px,
  sm: 8px,
  md: 12px,
  lg: 16px,
  xl: 20px,
  xxl: 24px,
  3xl: 32px,
  4xl: 40px,
);

$max-page-width: 1440px;
$max-page-width-sm: 1280px;

$g-primary: color(light-purple);
$g-primary-outline: color(white);

$g-shadows: (
  outline: get-outline-box-shadow,
  text: get-font-text-shadow,
  svg: get-svg-drop-shadow,
);

@function shadows($shadow, $colors, $radiuses: null) {
  @return meta.call(
    meta.get-function(map-get($g-shadows, $shadow), $module: 'u'),
    $colors,
    $radiuses
  );
}

%basic-shadow-primary {
  box-shadow: shadows(outline, (u.g-dimm($g-primary), u.g-dimmest($g-primary)));
}
%text-shadow-primary {
  text-shadow: shadows(text, ($g-primary, $g-primary, $g-primary, $g-primary));
}
%text-shadow-light-primary {
  text-shadow: shadows(text, ($g-primary, $g-primary));
}
%filter-shadow-primary {
  filter: shadows(svg, ($g-primary, u.g-dimm($g-primary)));
}

@each $key, $value in $colors {
  %basic-shadow-#{'' + $key} {
    box-shadow: shadows(outline, (u.g-dimm($value), u.g-dimmest($value)));
  }
  %text-shadow-#{'' + $key} {
    text-shadow: shadows(text, ($value, $value, $value, $value));
  }
  %text-shadow-light-#{'' + $key} {
    text-shadow: shadows(text, ($value, $value));
  }
  %filter-shadow-#{'' + $key} {
    filter: shadows(svg, ($value, u.g-dimm($value)));
  }
}
