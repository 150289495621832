$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

@mixin media-breakpoint-up($size) {
  @media (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}

@mixin media-breakpoint-down($size) {
  @media (max-width: #{map-get($breakpoints, $size) - 0.02}) {
    @content;
  }
}
