@use '/styles/variables' as *;
@use '/styles//utils' as u;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    font-weight: 600;
    font-size: 20px;
    color: color(white);
    margin-bottom: 4px;
  }

  .subtitle {
    color: color(light-gray);
    font-size: 15px;
    margin-bottom: 14px;
  }
}

.image-container {
  display: grid;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, white);
  margin-bottom: 20px;
  white-space: nowrap;
  border: 1px solid #ffffff14;

  .image {
    transition: transform 0.5s ease-in-out;
    object-position: center;
    object-fit: cover;
    width: 100%;
  }
}

.link:hover {
  span {
    @extend %text-shadow-light-primary;
  }

  svg {
    filter: shadows(svg, ($g-primary, u.g-dimmer($g-primary))) !important;
  }

  img {
    transform: scale(1.05);
  }
}

.link {
  font-size: 16px;
  display: flex;
  align-items: center;

  & span {
    transition: all 0.05s ease-in-out;
    margin-right: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;
    color: color(white);
  }

  & svg {
    filter: unset !important;
  }
}
