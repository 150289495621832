@import '/styles/variables';
@import '/styles/media';

.items {
  letter-spacing: $g-letter-spacing;
  display: flex;
  width: auto;
  flex-direction: column;
  border: 1px solid map-get($colors, dividers);

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  border-radius: map-get($border-radiuses, md);

  @include media-breakpoint-down(lg) {
    border: none;
  }

  padding-top: 0;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.transparentBackground {
    background-color: transparent;
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid map-get($colors, dividers);
  }
}

.placeholder-list-item {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: map-get($spaces, sm);
  padding: map-get($spaces, lg) map-get($spaces, 4xl);
  @include media-breakpoint-down(sm) {
    padding: map-get($spaces, lg) map-get($spaces, xl);
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: color(dividers);
}

@keyframes init {
  0% {
    background-color: transparentize($color: map-get($colors, g-white), $amount: 0.95);
  }
  100% {
    background-color: transparent;
  }
}
