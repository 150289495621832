@use '/styles/variables' as *;
@use '/styles/media' as *;

$pink: map-get($colors, g-pink);
$blue: map-get($colors, g-blue);

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

@mixin glow-effect($colors) {
  filter: shadows(svg, $colors, (1px, 1px, 5px));
}

.heading {
  position: relative;
  font-family: $g-heading-font-family;
  color: transparent;
  font-weight: 500;
  z-index: 0;
  -webkit-text-stroke: 1px $g-primary-outline;

  &.poppins {
    font-family: 'Poppins', sans-serif;
  }

  &.bold {
    font-weight: bold;
  }

  &.fill {
    color: $g-primary-outline;
  }

  &-glow {
    &::after,
    &::before {
      padding: inherit;
      margin: inherit;
      position: absolute;
      width: 100%;
      height: 100%;

      top: 0px;
      left: 0px;
      z-index: -1;
      filter: blur(1px);
      content: attr(data-content);
      -webkit-text-stroke: 2.5px $g-primary;
    }

    &::before {
      top: 0.5px;
      z-index: -2;
      opacity: 0.8;
      -webkit-text-stroke: 1px black;
      @include glow-effect(($g-primary, $g-primary, $g-primary));
    }
  }

  @each $key, $value in $colors {
    &-#{'' + $key} {
      &::before {
        @include glow-effect(($value, $value, $value));
      }
      &::after {
        -webkit-text-stroke: 2.5px $value;
      }
    }
  }
}

@each $key, $value in $heading-sizes {
  .#{'' + $key} {
    font-size: $value;
  }
}
