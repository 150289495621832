@use '/styles/variables' as *;
@use '/styles/media' as *;

.container {
  display: flex;
  transition: 0.3s;

  overflow: hidden;
  overflow-x: auto;

  scroll-snap-type: x mandatory;

  @extend .scroll-black;

  &.hide-scrollbar::-webkit-scrollbar {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.item {
  --item-width: 25%;
  --item-margin: 20px;

  flex: 0 0 calc(var(--item-width) - var(--item-margin));
  margin-right: var(--item-margin);
  scroll-snap-align: start;
  padding-bottom: 10px;
  min-height: var(--card-height, 160px);
}

.wrapper {
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.debug {
  display: flex;
}
