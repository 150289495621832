@use 'sass:list';
@use 'sass:string';

@import '/styles/media';

@function g-dimm($color) {
  @return transparentize($color, 0.5);
}

@function g-dimmer($color) {
  @return transparentize($color, 0.7);
}

@function g-dimmest($color) {
  @return transparentize($color, 0.9);
}

/* 
* the purpose of this function is to return value for box shadow depending on colors and radiuses
* input:
* $colors - scss list: (#000000, #000000)
* $radiuses - sccs list: (10px, 3em), (when optional - max 3 colors)
* when setting colors and radiuses explicitly: colors.length == radiuses.length
* return types: 
* 1. 0 0 25px #000000
* 2. 0 0 25px #000000, 0 0 50px #000000, 0 0 75px #000000
* 
* usage:
* box-shadow: get-outline-box-shadow((u.g-dimmer($g-primary), u.g-dimmest($g-primary))),
*     inset get-outline-box-shadow((u.g-dimmer($g-primary), u.g-dimmest($g-primary)));
* results in:
* box-shadow : 0 0 25px rgba(27, 225, 29, 0.3), 0 0 50px rgba(27, 225, 29, 0.3),
*     inset 0 0 25px rgba(27, 225, 29, 0.3), 0 0 50px rgba(27, 225, 29, 0.05);
*/

@function get-outline-box-shadow($colors, $radiuses) {
  $result: '';

  @if $radiuses == null {
    $radiuses: (25px, 50px, 70px);
    // ablity to use this func without having radiuses set explicit
  }

  @for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    $radius: nth($radiuses, $i);

    @if string.length($result) > 0 {
      $result: $result + ',' + ' 0 0 ' + $radius + ' ' + $color;
    } @else {
      $result: '0 0 ' + $radius + ' ' + $color;
    }
  }

  @return #{$result};
}

@function get-font-text-shadow($colors, $radiuses) {
  @if $radiuses == null {
    $radiuses: (10px, 15px, 20px, 50px);
    // ablity to use this func without having radiuses set explicit
  }
  @return get-outline-box-shadow($colors, $radiuses);
}

@function get-svg-drop-shadow($colors, $radiuses) {
  $result: '';

  @if $radiuses == null {
    $radiuses: (0.3em, 0.4em, 0.5em);
    // ablity to use this func without having radiuses set explicit
  }

  @for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    $radius: nth($radiuses, $i);

    @if string.length($result) > 0 {
      $result: $result + ' drop-shadow(0 0 ' + $radius + ' ' + $color + ')';
    } @else {
      $result: 'drop-shadow(0 0 ' + $radius + ' ' + $color + ')';
    }
  }

  @return #{$result};
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

.scroll-black {
  @include media-breakpoint-up(md) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
